<template>
    <div class="app-layout">
        <el-dropdown
            class="queue-system-menu"
            @command="_systemMenuCmd"
        >
            <span class="el-dropdown-link">
                ...<i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="cacheFiles">
                    Кешировать файлы
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <el-container>
            <el-container>
                <el-main>
                    <div class="queue">
                        <div class="queue-list">
                            <div
                                v-for="item in items"
                                :key="item.id"
                                class="queue-item"
                            >
                                <div>{{ item.number }} → {{ item.manager.name }}</div>
                            </div>
                        </div>
                    </div>
                    <audio
                        ref="cplayer"
                        src=""
                    />
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import { EQueue } from '@/api';
import CONFIG from '@/config';

export default {
    name: 'EQueueScreenModule',
    data() {
        return {
            items: [],
            playQueue: [],
            currentPlayback: {},
            interval: null,
            latestUpdate: null,
            soundPlaying: false
        };
    },

    mounted() {
        this._loadQueue();
        this.$echo.channel('EQueue')
            .listen('CallCustomer', () => {
                this._loadQueue();
            });
    },

    methods: {
        _systemMenuCmd(cmd) {
            if (cmd === 'cacheFiles') {
                this._cacheFiles();
            }
        },
        async _cacheFiles() {
            for (let i = 1; i <= 1000; i++) {
                try {
                    await EQueue.Screen1.cacheNumber(i);
                } catch (e) {
                }
            }
        },
        _loadQueue(playSound = true) {
            return EQueue.Screen1.getQueue()
                .then((response) => {
                    let latestUpdate = Math.max.apply(null, response.attributes.queue.map(item => {
                        return new Date(item.updated_at.replace(/-/g, '/'));
                    }));

                    if (!this.latestUpdate || this.latestUpdate < latestUpdate || this.items.length !== response.attributes.queue.length) {
                        this._updateQueue(response.attributes.queue, latestUpdate, playSound);
                    }
                });
        },

        _poll(callback, timeout) {
            this.interval = setTimeout(() => {
                callback.apply(this);
                this._poll(callback, timeout);
            }, timeout);
        },

        _updateQueue(queue, lastUpdate, playSound) {
            this.latestUpdate = lastUpdate;
            let number = this.items.map(item => item.number);
            let newNumbers = queue.map(item => {
                return { number: item.number, manager: item.manager.id };
            });
            let newCustomers = newNumbers.filter(item => !number.includes(item.number));
            if (playSound && newCustomers[0]) {
                this._playComplexSound(newCustomers[0].number, newCustomers[0].manager);
            }
            this.items = queue;
        },

        _resetPlayer() {
            this.soundPlaying = false;
            let playBack = this.playQueue.shift();
            if (playBack) {
                this._playComplexSound(playBack.number, playBack.managerId);
            }
        },

        _playComplexSound(number, managerId) {
            let playback = { number: number, managerId: managerId };
            if (this.soundPlaying) {
                this.playQueue.push(playback);
                return;
            }
            this.currentPlayback = playback;
            this.soundPlaying = true;
            this._playSound()
                .then(this._playBefore)
                .then(this._playNumber)
                .then(this._playName)
                .then(this._resetPlayer);
        },

        _playSound() {
            return new Promise((resolve) => {
                this._play('/storage/speech/notify.mp3', resolve);
            });
        },

        _playBefore() {
            return new Promise((resolve) => {
                this._play('/storage/speech/before.ogg', resolve);
            });
        },

        _playNumber() {
            return new Promise((resolve) => {
                let number = this.currentPlayback.number;
                this._play(`/storage/speech/numbers/${number}.ogg`, resolve);
            });
        },

        _playName() {
            return new Promise((resolve) => {
                let managerId = this.currentPlayback.managerId;
                this._play(`/storage/speech/names/${managerId}.ogg`, resolve);
            });
        },

        _play(url, onEnded) {
            this.$refs.cplayer.pause();
            this.$refs.cplayer.src = CONFIG.API_HOST + url;
            this.$refs.cplayer.addEventListener('ended', () => {
                this.$refs.cplayer.pause();
                onEnded();
            });
            return this.$refs.cplayer.play()
                .catch(e => {
                });
        }
    }
};
</script>

<style>
.queue {
    display: flex;
    align-items: center;
    justify-content: center;
}

.queue-system-menu {
    position: absolute;
    right: 20px;
}

.queue-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.queue-item {
    font-size: 96px;
    color: #979797;
}

.queue-item:first-child {
    font-size: 160px;
    color: #000;
    align-self: center;
}
</style>
